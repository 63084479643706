export const STATISTICS_TILES = [
  { label: 'Total Clients Provisioned', key: 'total', query: null },
  { label: 'Clients Who Logged In', key: 'loggedIn', query: ['firstLogIn ine'] },
  { label: 'Clients Locked', key: 'locked', query: ['customStatus co locked',] },
  { label: 'Clients Marked Complete', key: 'submitted', query: ['customStatus co completed'] },
  { label: 'Clients Not Started', key: 'notStarted', query: ['customStatus co not started'] },
  { label: 'E-organize', key: 'eOrganize', query: ['eOrganize eq true'] },
  { label: 'Clients Provisioned to Spoke', key: 'spoke', query: ['assignedToSpoke eq true'] },
];

export const ASSIGNED_TO_ME = 'Assigned to Me';
export const SHOW_ALL = 'All';