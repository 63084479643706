import checkSection from "./checkSection";

const checkFormErrors = (globalState, currentFormKeys) => {

    let formErrors = 0;
    currentFormKeys.forEach((formKey) => {
        const formSections = globalState.get(formKey);
        
        if (formSections && formSections !== null) {
            formSections.forEach((section) => {
                formErrors += checkSection(section)
            })
        }
    })

    return formErrors;

}

export default checkFormErrors;