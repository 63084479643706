// common modules
import React, { useEffect, useState, useRef } from 'react';
import _, { set } from 'lodash';
// MUI imports
import { Box, TextField } from '@mui/material';
import { Checkbox, useToggle, Button } from 'react-md';
import './emailInvites.css';
import {
  DataGridPro, gridClasses, getGridNumericOperators
} from '@mui/x-data-grid-pro';
//MUI pagination
import CustomToolbar from '@components/organizerList/customToolbar';
// custom modules
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';
import { MAX_PREFETCH_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, OPERATORS_MAP, DEFAULT_PAGE_LIMIT, SORT_TO_DATA_MAP, FILTER_TO_DATA_MAP } from '@utilities/constants/welcomeEmailInvites.js';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';
import moment from 'moment/moment';

let mockData = [
  {
      "csl": "053 – Minneapolis",
      "count": '5,000',
      "status": "Active - 01/18/2025 at 08:38PM CST",
      "id": "66995a93422bb18152b90e64"
  },
  {
    "csl": "061 – Little Rock",
    "count": '2,351',
    "status": "Inactive - 01/11/2025 at 09:38AM CST",
    "id": "66995a93422bb18152b90e63"
  },
  {
    "csl": "059 – Wisconsin",
    "count": '8,351',
    "status": "Active - 01/22/2025 at 12:38PM CST",
    "id": "66995a93422bb18152b90e66"
  },
  {
    "csl": "085 – Los Angeles",
    "count": '14,351',
    "status": "Active - 01/02/2025 at 01:38PM CST",
    "id": "66995a93422bb18152b90e67"
  },
  {
    "csl": "081 – Dallas",
    "count": '10,551',
    "status": "Active - 01/05/2025 at 05:38PM CST",
    "id": "66995a93422bb18152b90e61"
  },
  {
    "csl": "025 – Seattle",
    "count": '7,734',
    "status": "Active - 01/06/2025 at 02:38PM CST",
    "id": "66995a93422bb18152b90e60"
  },
  {
    "csl": "027 – Fargo",
    "count": '72',
    "status": "Active - 01/15/2025 at 03:38PM CST",
    "id": "66995a93422bb18152b90e78"
  },
  {
    "csl": "046 – Tacoma",
    "count": '243',
    "status": "Active - 01/13/2025 at 03:50PM CST",
    "id": "66995a93422bb18152b90e34"
  },
  {
    "csl": "034 – San Diego",
    "count": '5,790',
    "status": "Active - 01/07/2025 at 12:45PM CST",
    "id": "66995a93422bb18152b90e56"
  },
  {
    "csl": "022 – Saint Paul",
    "count": '3,421',
    "status": "Active - 01/09/2025 at 12:01PM CST",
    "id": "66995a93422bb18152b90e99"
  },
  {
    "csl": "029 – Rochester",
    "count": '3,421',
    "status": "Active - 01/10/2025 at 11:11PM CST",
    "id": "66995a93422bb18152b90e77"
  },
  {
    "csl": "019 – Charlotte",
    "count": '4,158',
    "status": "Active - 01/17/2025 at 11:47PM CST",
    "id": "66995a93422bb18152b90e23"
  },
  {
    "csl": "020 – Bellevue",
    "count": '1,138',
    "status": "Active - 01/13/2025 at 02:28PM CST",
    "id": "66995a93422bb18152b90e12"
  },
  {
    "csl": "015 – Portland",
    "count": '6,318',
    "status": "Active - 01/05/2025 at 09:28PM CST",
    "id": "66995a93422bb18152b90e33"
  },
  {
    "csl": "033 – Las Vegas",
    "count": '4,007',
    "status": "Active - 01/07/2025 at 06:28PM CST",
    "id": "66995a93422bb18152b90e52"
  },
  {
    "csl": "021 – Houston",
    "count": '7,053',
    "status": "Active - 01/10/2025 at 02:28PM CST",
    "id": "66995a93422bb18152b90e09"
  },
];

function CountInputValue(props) {
  const { item, applyValue, focusElementRef } = props;


  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="custom-text-filter-operator"
        placeholder="Filter value"
        label="Filter value"
        variant="standard"
        value={Number(item.value)}
        onChange={handleFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
    </Box>
  );
}

function TextInputValue(props) {
  const { item, applyValue, focusElementRef } = props;


  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="custom-text-filter-operator"
        placeholder="Filter value"
        label="Filter value"
        variant="standard"
        value={item.value}
        onChange={handleFilterChange}
        type="text"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
    </Box>
  );
}

function ManageEmailInvites(props) {
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [isLoaded, enable, disable] = useToggle(false);
  const [paginatedLoad, setPaginatedLoad] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const previousOrganizerQuery = useRef(null);
  const [prefetchedData, setPrefetchedData] = useState([]);
  const [data, setData] = useState([]);
  const payload = {
    defaultFilter: DEFAULT_FILTER_QUERY,
    defaultSort: DEFAULT_SORT_QUERY,
    defaultPageSize: DEFAULT_PAGE_LIMIT,
    filterDataMap: FILTER_TO_DATA_MAP,
    sortDataMap: SORT_TO_DATA_MAP,
    operatorsMap: OPERATORS_MAP,
    defaultPrefetchSize: MAX_PREFETCH_LIMIT,
  };
  const {
    handleFilterModelChange,
    handleSortModelChange,
    handlePageModelChange,
    handlePageSizeChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
    prefetchSize,
    currentFilterModel,
    currentSortModel,
  } = useServerSideMUI(payload);
  const filterHighlight = currentFilterModel?.items?.[0]?.value ? 'filterButtonHighlight' : 'filterButton';

  const pageSize = 15;

  const columnsGrid = [
    { field: 'csl', headerName: 'CSL', type: 'text', width: 550, editable: false, canEdit: false, required: true, },
    {
      field: 'count',
      headerName: 'User Count at Activation',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: false,
      required: true,
      sortComparator: (a, b) => {
        return Number(a.replaceAll(',', '')) - Number(b.replaceAll(',', ''));
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      width: 300,
      editable: false,
      canEdit: false,
      sortComparator: (a, b) => {
        const x = a.replaceAll('Inactive - ', '').replaceAll('Active - ', '').replace(' at', '')
        .replaceAll('/', '-').replace(' CST', '').replace('PM', ' PM').replace('AM', ' AM');
        const y = b.replaceAll('Inactive - ', '').replaceAll('Active - ', '').replace(' at', '')
        .replaceAll('/', '-').replace(' CST', '').replace('PM', ' PM').replace('AM', ' AM');
      
        return (new Date(moment(x, 'MM-DD-YYYY LT')).getTime() - new Date(moment(y, 'MM-DD-YYYY LT')).getTime());
      }
    },
  ];

  const navItems = [
    { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
    { to: NAV.WELCOME_EMAIL_INVITES, label: 'Welcome Email Invites', current: true }
  ];

  const buildOrganizerQuery = (limit, offset, sorting, filterMethod, filtering) => {
    const filterQuery = filtering?.length ? filtering.map(x => `&filter=${x}`).join('') : '';
    const filterMethodQuery = filterMethod ? `&filterMethod=${filterMethod}` : '';
    const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param, query]) => `&${param}=${query}`).join('') : '';
    return `${sortQuery}${filterMethodQuery}${filterQuery}&limit=${limit}&offset=${offset}`
  };

  useEffect(() => {
    if (!isLoaded) {
      setPaginatedLoad(true);
      const totalOffset = currentPageNumber * pageSize;
      const prefetchOffset = Math.floor(totalOffset / prefetchSize) * prefetchSize;
      const prefetchPageOffset = totalOffset % prefetchSize
      const organizerQuery = buildOrganizerQuery(prefetchSize, prefetchOffset, sortQueryParameters, filterMethodParameters, filterQueryParameters);
      // If the resulting queries are the same, grab organizer data from the prefetched set
      if (previousOrganizerQuery.current !== null && organizerQuery === previousOrganizerQuery.current) {
        const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
        setData(resultSlice);
        setPaginatedLoad(false);
        enable();
        return;
      }
      // Call API here to get Clients and Invite status

      const results = mockData;
      const total = mockData.length;

      const resultSlice = results.slice(totalOffset, totalOffset + pageSize);

      setPrefetchedData(resultSlice);
      setData(resultSlice);
      setTotalResults(total);
      enable();
      setPaginatedLoad(false);
    }
  }, [sortQueryParameters, filterMethodParameters, filterQueryParameters, isLoaded, pageSize, prefetchSize, currentPageNumber])

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 30 : 6,
      bottom: params.isLastVisible ? 9 : 6,
    };
  }, []);

  const extractDate = (text) => {
    const pattern = /\b\d{2}\/\d{2}\/\d{4}\b/;

    const match = text.match(pattern);

    if (match) {
      return match[0];
    } else {
      return null;
    }
  }

  const countOnlyOperators = [
    {
      label: 'contains',
      value: 'contains',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return value.value.replace(',', '').includes(filterItem.value)
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'equals',
      value: 'equals',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return Number(value.value.replace(',', '')) === Number(filterItem.value);
        };
      },
      InputComponent: CountInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is greater than',
      value: 'greaterThan',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return Number(value.value.replace(',', '')) >= Number(filterItem.value);
        };
      },
      InputComponent: CountInputValue,
      InputComponentProps: { type: 'number' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is less than',
      value: 'lessThan',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return Number(value.value.replace(',', '')) <= Number(filterItem.value);
        };
      },
      InputComponent: CountInputValue,
      InputComponentProps: { type: 'number' },
      getValueAsString: (value) => `${value}`,
    },
  ];

  const statusOnlyOperators = [
    {
      label: 'contains',
      value: 'contains',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return value.value.includes(filterItem.value)
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'equals',
      value: 'equals',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return value.value === filterItem.value;
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is before',
      value: 'before',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return moment(extractDate(value.value)).isBefore(moment(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is after',
      value: 'after',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return moment(extractDate(value.value)).isAfter(moment(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
  ];

  const [sortModel, setSortModel] = React.useState([
    {}
  ]);

  const columns = React.useMemo(
    () =>
      columnsGrid.map((col) => {
        if (col.field === 'count') {
          return {
            ...col,
            filterOperators: countOnlyOperators,
          };
        } else if (col.field === 'status') {
          return {
            ...col,
            filterOperators: statusOnlyOperators,
          };
        } else {
          return col;
        }
      }),
    [columnsGrid],
  );

  let selectedRows = [];
  const activateSelectedRows = () => {
    data.forEach((originRow) => {
      if (JSON.stringify(selectedRows).includes(originRow.id)) {
        originRow.status = `Active - ${moment().format('MM/DD/YYYY')} at ${moment().utcOffset('-06:00').format('hh:mm A')} CST`
      }
    });
  };

  const deactivateSelectedRows = () => {
    data.forEach((originRow) => {
      if (JSON.stringify(selectedRows).includes(originRow.id)) {
        originRow.status = `Inactive - ${moment().format('MM/DD/YYYY')} at ${moment().utcOffset('-06:00').format('hh:mm A')} CST`
      }
    });
  };

  const onSelectionModelChange = (item) => {
    const selectedItems = new Set(item);
    selectedRows = data.filter((row) => selectedItems.has(row.id));
  }

  // GROWTH: Refactor the DataGridPro component to a generic/reusable component
  return (
    <div className="pracDashboardSize">
      <Breadcrumb items={navItems} />
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '95%', margin: '0 auto'}}>
        <h1>Welcome Email Invites</h1>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <button class="rmd-button rmd-button--text rmd-button-welcome-email-invites" style={{margin: '0 5px'}} onClick={() => {
            deactivateSelectedRows();
          }}>
              Deactivate
          </button>
          <button class="rmd-button rmd-button--text rmd-button-welcome-email-invites" style={{margin: '0 5px'}} onClick={() => {
            activateSelectedRows();
          }}>
              Activate
          </button>
        </div>
      </div>
      <Box sx={{ height: 'auto', width: '100%' }} data-testid='pracdash-columnheader'>
        <DataGridPro
          checkboxSelection
          onRowSelectionModelChange={onSelectionModelChange}
          loading={paginatedLoad}
          filterMode={'client'}
          disableMultipleColumnsFiltering={false}
          filterModel={currentFilterModel}
          onFilterModelChange={(filterModel) => {
            handleFilterModelChange(filterModel);
            disable();
          }}
          disableColumnPinning
          filterDebounceMs={300}
          getRowSpacing={getRowSpacing}
          rows={data}
          columns={columns}
          pageSizeOptions={[pageSize, 30]}
          columnVisibilityModel={{}}
          disableColumnResize={true}
          disableColumnSelector
          disableMultipleRowSelection={false}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          paginationMode={'server'}
          rowCount={totalResults}
          onPaginationModelChange={(props) => {
            handlePageSizeChange(props.pageSize);
            handlePageModelChange(props.page);
            disable();
          }}
          sortingMode={'client'}
          paginationModel={{ pageSize: pageSize, page: currentPageNumber }}
          sortModel={sortModel}
          initialState={{
            ...data.initialState,
            sorting: {
              ...data.initialState?.sorting,
              sortModel: [{
                field: 'status',
                sort: 'asc'
              }]
            }
          }}
          onSortModelChange={(sortModel) => {
            setSortModel(sortModel);
            disable();
          }}
          localeText={{ toolbarFilters: "" }}
          autoHeight={true}
          rowHeight={98}
          pagination
          disableVirtualization
          getDetailPanelHeight={({ row }) => 'auto'}
          slots={{
            toolbar: CustomToolbar,
          }}

          slotProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
              filterHighlight
            },

          }}
          sx={{
            [`& .MuiDataGrid-columnsPanel > div:first-child`]: { display: "none" },
            [`& .${gridClasses.row}`]: {
              bgcolor: '#ffffff',
              borderRadius: 1,
              margin: 4,
              width: '91%',
              border: 0.25,
              borderColor: '#E5E5E5'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnHeadersInner': {
              margin: 4
            },
            '& .MuiDataGrid-detailPanelToggleCell': {
              padding: 3
            },
            '& .MuiDataGrid-detailPanel': {
              marginLeft: 4,
              marginRight: 5,
              width: '95.2%',
            },
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none'
            },
            '& .MuiDataGrid-columnHeaderCheckbox, & .MuiDataGrid-cellCheckbox': {
              marginLeft: '25px'
            },
          }}
        />
      </Box>
    </div>
  );
}

export default ManageEmailInvites;