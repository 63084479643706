import { Button, Dialog, Link as ReactMDLink, } from 'react-md';
import nextId from 'react-id-generator';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as IMG from '@utilities/constants/images'; 

// custom modules
import '@components/dialog/warningDialog/WarningDialog.css';
import * as STR from '@utilities/constants/strings';

function SubmitDialog(props){
    const { 
        onCancelSubmit,
        onConfirmSubmit,
        visible,
        isAboutYouCompleted,
        isAboutYourYearCompleted,
        refundPaymentPreferences } = props;

    const Link = (props) => {
        return (
            <ReactMDLink
                component={ReactRouterLink}
                {...props}
            />
        );
    }; 

    const isRefundPaymentPreferencesCompleted = refundPaymentPreferences.isRequired ? refundPaymentPreferences.isCompleted : true;
    const requiredCardsCompleted = isAboutYouCompleted && isAboutYourYearCompleted && isRefundPaymentPreferencesCompleted;

    const getDialogTitle = () => {
        return requiredCardsCompleted ? STR.ARE_YOU_SURE : STR.ERROR;
    }

    const renderDialogContent = ()=>{

        if (!requiredCardsCompleted){
            return(
                <div className="WarningDialogTextContainer" data-testid="dialog-requiredCardsNotCompleted">
                <div className="WarningDialogText1">
                    Please review the information within the Required cards on the dashboard.
                    Once you complete your review, please click the <span style={{color: '#2d3c59'}}>Complete</span> button of the Required cards. 
                </div>
            </div>
            );
        } else{
            return (
                <div className="WarningDialogTextContainer" data-testid="dialog-completed">
                <div className="WarningDialogText1" > {STR.BY_SELECTING}</div>
                <div className="WarningDialogText1" style={{textAlign: 'left', margin: '0 40px 0 50px', fontSize: '11px'}}> {STR.SUBMIT_1}</div>
                <div className="WarningDialogText1" style={{textAlign: 'left', margin: '0 40px 0 50px', fontSize: '11px'}}> {STR.SUBMIT_2}</div>
                </div>
            );
        }
    };

    return(
        <>
            <Dialog
                id={`warning-dialog${nextId()}`}
                role="alertdialog"
                visible={visible}
                onRequestClose={()=> {}}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="submit-dialog"
            >
                <div className='WarningDialogInnerContainer' >
                    <div className='WarningSignContainer'>
                        {!requiredCardsCompleted && (
                            <img className="warningDialogIcon" width="30" height="30" alt="Warning icon" src={IMG.warningIcon} />
                        )}
                        <div className="SuccessDialogTitle"> {getDialogTitle()} </div>
                    </div>
                    {renderDialogContent()}
                    <div className="warningDialogButton">
                        {(!requiredCardsCompleted) && <Button className="warningDialogConfirm" id="dialog-cancel" onClick={onCancelSubmit} > {STR.CLOSE} </Button>}
                        {(requiredCardsCompleted) && (
                        <>
                            <Button className="warningDialogCancel"  id="dialog-save" onClick={onCancelSubmit} > {STR.SAVE_CONT} </Button>
                            <Button className="warningDialogConfirm" style={{fontSize: '12px'}} id="dialog-confirm" onClick={onConfirmSubmit} > {STR.YES} </Button>
                        </>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default SubmitDialog;