/*
/App

The main entry point for application components.
*/

import './App.scss';
import './App.css';
// common modules
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import _ from 'lodash';
import { useIntercom } from 'react-use-intercom';

// custom modules
import { reactPlugin, appInsights } from '@utilities/applicationInsights.js';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import { getRoutes } from '@utilities/routes';
import TopBar from '@components/header/topBar';
import HeaderMenu from '@components/header/headerMenu';
import HeaderImage from '@components/header/headerImage';
import AppFooter from '@components/footer';
import ProgressDialog from '@components/dialog/progressDialog';
import CustomDialog from '@components/dialog/customDialog';

import api, { initRoles, hasRole, scopes, getEmail } from '@utilities/claApi';
import { authenticated, getAccount, injectLocalDevelopmentTokens } from '@utilities/authentication';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';
import lastUserActivityDate, { lastUserActivityDateSetter } from '@utilities/helpers/lastUserActivityDate';
import { LicenseInfo } from '@mui/x-license-pro';
import UploadWarningDialog from '@components/dialog/uploadWarningDialog';
import ShowLastFormSave from '@components/showLastFormSave';
import { calculateDashboardSummary } from '@utilities/helpers/calculateDashboardSummary.js';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

//redirect to AAD login for sso, call when unauthenticated
const RedirectToLogin = () => {
	const { instance, inProgress } = useMsal();

	// when interaction is complete, redirect to login
	if (inProgress === InteractionStatus.None) {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// inject development tokens into cache then refresh entire page
			injectLocalDevelopmentTokens();
			window.location.reload();
		} else {
			// redirect to MSAL login
			instance.loginRedirect({ scopes });
		}
	}
};

function App() {
	const {
		selectState,
		REDUX,
		dispatch,
		ACTION,
		setEntityFormState,
		updateUploadList,
	} = usePageFramework();
	const { boot } = useIntercom();

	// Roles
	const isAuthenicated = selectState(REDUX.IS_AUTHENTICATED);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isClient = selectState(REDUX.IS_CLIENT);

	const activeReturn = selectState(REDUX.ACTIVE_RETURN);

	// Progress Dialog
	const isProgressVisible = selectState(REDUX.PROGRESS_VISIBLE);
	const progressText = selectState(REDUX.PROGRESS_TEXT);

	// Custom Dialog
	const showCustomDialog = selectState(REDUX.SHOW_CUSTOM_DIALOG);

	//Upload Warning Dialog
	const isUploadWarningVisible = selectState(REDUX.UPLOAD_WARNING_VISIBLE);
	const dupFiles = selectState(REDUX.DUPLICATE_UPLOAD_FILES) || [];
	const uploadProps = selectState(REDUX.UPLOADS_PROPS);
	const uploadList = selectState(REDUX.UPLOAD_LIST);

	const availableRoutes = getRoutes(isAuthenicated, isPractitioner, isAdmin, isClient, activeReturn).reduce((prev, page) => {
		prev.push(<Route path={page.to} exact component={page.componentObject} key={page.to} />);
		return prev;
	}, []);

	lastUserActivityDateSetter.lastUserActivityDate = lastUserActivityDate(selectState);

	// GROWTH: Handle loading data with multiple client numbers
	useEffect(() => {
		let isAdmin = false;
		let isPractitioner = false;
		//eslint-disable-next-line
		let isClient = false;
		let email = '';

		// if authenticated, load app
		if (authenticated()) {
			const account = getAccount();

			// GROWTH: Would this be more appropriate to have following sign in?
			if (account) {
				// set Authenticated User Context for AppInsights
				appInsights.setAuthenticatedUserContext(
					account.username ?
						account.username : account.idTokenClaims.email);

				// boot intercom as current user
				boot({
					name: account.name,
					email: account.username ? account.username : account.idTokenClaims.email,
					hideDefaultLauncher: true
				});
			}

			initRoles()
				.then(() => {
					// determine user roles and store in state
					isAdmin = hasRole(['Administrator']) ?? false;
					isPractitioner = (!hasRole(['Administrator']) && hasRole(['Employee'])) ?? false;
					isClient = (!hasRole(['Administrator']) && !hasRole(['Employee']) && hasRole(['Client'])) ?? false;
					email = getEmail();

					dispatch(ACTION.setIsAdmin(isAdmin));
					dispatch(ACTION.setIsPractitioner(isPractitioner));
					dispatch(ACTION.setIsClient(isClient));
					dispatch(ACTION.setAuthUserEmail(email));

					// GROWTH: Refactor app startup/data loading code for API/performace improvements

					const limit = 50;
					if (isClient) {
						// when client first login, set false, so modal will pop
						dispatch(ACTION.setHasSkipEntryStep(false));
						// load organizers
						return api.get(`/organizers?limit=${limit}`)
							.then((response) => response.data.results)
							.then((organizers) => {
								if (organizers.length === 0) return;

								const organizer = organizers.find((org) => org.year === currentYear);
								let priorYearByClient = {};
								_.forEach(organizers, organizer => {
									if (!priorYearByClient[organizer?.client?.number]) {
										priorYearByClient[organizer?.client?.number] = [{ name: organizer.year, value: organizer.year, id: organizer.id }];
									} else {
										priorYearByClient[organizer?.client?.number].push({ name: organizer.year, value: organizer.year, id: organizer.id });
									}
								});
								let priorOrganizer = _.find(organizers, (obj) => {
									return obj.client.number === organizer?.client?.number && obj.year === organizer.year - 1 && obj.id !== organizer.id;
								});
								if (priorOrganizer) {
									dispatch(ACTION.setPriorYearDetails({ priorYear: priorOrganizer.year, priorOrganizerId: priorOrganizer.id, priorYearLookup: priorYearByClient[organizer?.client?.number] }));
								} else {
									dispatch(ACTION.setPriorYearDetails({ priorYear: false, priorOrganizerId: undefined, priorYearLookup: priorYearByClient[organizer?.client?.number] }));
								}


								if (!organizer) return;

								// get full organizer details
								return api.get(`/organizers/${organizer.id}`)
									.then((response) => response.data);
							})
							.then(async (organizer) => {
								if (!organizer) return;
                                
                                const {
                                    data: {
                                        id: dashboardId,
                                        dashboard,
                                    }
                                } = await api.get(`organizers/${organizer.id}/dashboard`);

								let hasVehicleData = false;

								const {
									id,
									forms,
									locked,
									client,
									status,
									year,
									entryExperience,
									firstLogIn,
									spouse
									//GROWTH: will add taxpayer property here too in the future
								} = organizer;

								if (spouse) {
									dispatch(ACTION.setSpouseEmail(spouse.email || ''))
								}

								api.get(`/organizers/${id}/notes`).then((response) => {
									dispatch(ACTION.setFormNotes(response.data.results));
								})


								// get prior year data for organizer
								const priorData = await api.get(`/organizers/${id}/prior`).then((response) => {
									dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
									return response.data.data.taxData;
								}).catch((err) => {
									console.error(err);
								});

								// load documents list of organizer
								await updateUploadList(id).catch((err) => {
									console.error('Failed to retrieve uploads', err);
								});

								// store organizer details in state
								if (dashboard) {
									dispatch(ACTION.setLastSaveFormTime(dashboard?.[0]?.cards?.[0].lastFormSavedOn || null));
									dispatch(ACTION.setDashboard(dashboard));
								} else {
									// save dashboard required forms based on py data
									let dashCopy = _.cloneDeep(dashboard || defaultDashboard);
									dashCopy = setRequiredForms(priorData?.priorYear || [], dashCopy);
									const { summary } = await calculateDashboardSummary(dashboardId, dashCopy, organizer);
									const data = { lastUserActivityOn: new Date(), dashboardSummary: summary };
									api.put(`/organizers/${organizer.id ?? ''}`, data).catch((error) => {
										console.error(error);
										console.error('Unable to update organizer');
									});
									// save dashboard 
									const dashboardData = { dashboard: dashCopy };
									api.put(`/organizers/${organizer.id ?? ''}/dashboard/${dashboardId}`, dashboardData).catch((error) => {
										console.error(error);
										console.error('Unable to save dashboard');
									});
									
									dispatch(ACTION.setDashboard(dashCopy));
								}

								//logs a date and time for when the client first logs into an organizer and if the organizer is from the current tax year
								if (!firstLogIn) {
									const data = { firstLogIn: new Date() };
									api.put(`/organizers/${organizer.id ?? ''}`, data).catch((error) => {
										console.error(error);
									});
								}
								
								const formKeys = [];
								forms?.forEach((form) => {
									if (!formKeys.includes(form.key)) {
										dispatch(ACTION.setForm(form.key, form.data));
										hasVehicleData = hasVehicleData || _.startsWith(form.key, REDUX.VEHICLE);
										formKeys.push(form.key);
									}
								});

								if (priorData && priorData.priorYear && !hasVehicleData) {
									const vehicleFormData = loadVehicleData(priorData.priorYear, year);
									if (vehicleFormData && Object.keys(vehicleFormData).length) {
										Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
											setEntityFormState(vehicleKey, vehicleForm, id);
										});
									}
								}

								// Set organizer metadata
								dispatch(ACTION.setActiveReturn({
									displayName: client.name,
									clientNumber: client?.number ?? '',
									formStatus: status,
									currentYear: year
								}));
								dispatch(ACTION.setOrganizerId(id));
								dispatch(ACTION.setYear(year));
								dispatch(ACTION.setLockForms(locked || false));
								dispatch(ACTION.setIsSaveSuccess(id ? true : false));
								dispatch(ACTION.setCompletedEntryStep(entryExperience?.completedStep > 0));
							});
					}

					if (isAdmin || isPractitioner) {
						// Load locking permissions for the Exchange Manager
						return api.get('/users/me').then((response) => {
							const { userLockPermission } = response.data;

							dispatch(ACTION.setLockingPermission(userLockPermission || false));
						});
					}
				}).catch((err) => {
					if (!isAdmin && !isPractitioner) console.error('Contact admin to get set up');
					console.error('Err: ', err);

				}).finally(() => {
					dispatch(ACTION.setisAuthenticated(authenticated() ?? false));
				});
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div className='App'>
			<TopBar />
			<HeaderMenu />
			<HeaderImage />
			<>
				<AuthenticatedTemplate>
					<Switch>
						{availableRoutes}
					</Switch>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<RedirectToLogin />
				</UnauthenticatedTemplate>
				<ShowLastFormSave className='lastSaveMsgFt' isNavBar = {true} />
			</>
			<AppFooter />
			<ProgressDialog visible={isProgressVisible ?? false} loadingText={progressText ?? ''} />
			<CustomDialog visible={showCustomDialog || false} />
			<UploadWarningDialog visible={isUploadWarningVisible || false} dupFiles={dupFiles} uploadProps={uploadProps} uploadList={uploadList} />
		</div>
	);
}

export default withAITracking(reactPlugin, App);