import { useState } from 'react';
import { DropdownMenu } from 'react-md';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import nextId from 'react-id-generator';
import usePageFramework from '@utilities/hooks/usePageFramework';
import InlineUploadButton from '../buttons/uploadButtons/inlineUploadButton/index';
import InlineNotApplicableButton from '../buttons/notApplicableButton/index';
import InlineRemoveButton from '../buttons/removeButton';
import InlineClearButton from '../buttons/clearButton';
import ViewButton from '../viewButton';
import { find } from 'lodash';

function LineItemButtons(props) {
    const { group, index, fns, isFormLocked, entityIndex, formSections } = props;
    const { selectState, REDUX, } = usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const groupLineItem = group.lineItems[index] ? group.lineItems[index][0] : null;
    //eslint-disable-next-line
    const [ buttonState, setButtonState] = useState(groupLineItem?.uploaded);
    const [ naState, setNAState] = useState(groupLineItem?.notApplicable);
   
    // TODO: check if will error on 1040 upload button
    // if (!group.lineItemDetails.lineButtons || !uploadList) return (null);
    if (!group.lineItemDetails.lineButtons ) return (null);

    const lineButtons = group.lineItemDetails.lineButtons;
    const lineUploadSectionName = lineButtons[0]?.uploadSectionName;
    const indexedUploadSectionName = entityIndex || entityIndex === 0 ? `${entityIndex}-${index}-${lineUploadSectionName}` : `${index}-${lineUploadSectionName}`;
    const filteredList = uploadList?.filter(x => x?.section?.toLowerCase().trim() === `${indexedUploadSectionName?.toLowerCase().trim()}`);
    const idGen = nextId();
    const items = [
        // {
        //     children: 'Remove File',
        //     onClick: () => {
        //         groupLineItem.uploadedFile = {};
        //         groupLineItem.uploaded = false;
        //         setButtonState(!buttonState);
        //     },
        // }
    ];

    const setLineDisabled = () => {
        setNAState(!naState);
        groupLineItem.notApplicable = !naState;
        group.currentEntity = -1;
        fns.saveForm(groupLineItem);
    };

    const setLineUploaded = () => {
        groupLineItem.uploaded = true;
        groupLineItem.drawerVisible = true;
        group.currentEntity = -1;
        setButtonState(groupLineItem.uploaded);
        fns.saveForm(groupLineItem);
    };

    const removeLineItem = () => {
        group.currentEntity = -1;
        group.lineItems.splice(index, 1);
        group.entities?.splice(index, 1);

        if (formSections) {
            formSections.forEach((section, idx) => {
                if (idx > 1 && section.groups?.[1]?.groupType === 'bondsItem') {
                    section.groups[1].bonds.splice(index, 1);
                }
            })
        }
        
        const removeBtn = find(group?.lineItemDetails?.lineButtons, {button: 'remove'});
        if (removeBtn?.logicFunction) {
            removeBtn?.logicFunction.forEach((logic) => {
                if(logic.isDifferentGroup){
                    fns.triggered(logic.trigger, index, group);
                }else if(fns.sectionFieldLogic){
                    fns.sectionFieldLogic(logic.trigger, {parentIndex: index, parentGroup: group});
                }   
            });
        }
        fns.saveForm();
    };

    const clearLineItem = () => {
        group.currentEntity = -1;
        console.log('cleared', group);
        const clearBtn = find(group?.lineItemDetails?.lineButtons, {button: 'clear'});

        group.lineItems[index].forEach(lineItemField => {
            if (clearBtn.retainFieldName && lineItemField.name !== clearBtn.retainFieldName) {
                if (lineItemField.default) {
                    lineItemField.default = lineItemField.type === 'label' ? lineItemField.label : '';
                }
            }
        });

        if (group.entities) {
            group.entities[index].sections.forEach(section => section.groups.forEach(group => {
                group.fields.forEach(groupField => {
                    if (groupField.default) {
                        groupField.default = groupField.type === 'label' ? groupField.label : '';
                    }
                });
            }));
        }

        // if (clearBtn?.logicFunction) {
        //     clearBtn.logicFunction.forEach((logic) => {
        //         logic.isDifferentGroup && fns.triggered ? fns.triggered(logic.trigger, field.default, null) :
        //         fns.sectionFieldLogic && fns.sectionFieldLogic(logic?.trigger, {group, index, logic, parentGroup, parentIndex, section, fns});
        //     });
        // }

        fns.saveForm();
    };

    const buttons = lineButtons.map((button) => {
        switch (button.button) {
        case 'upload':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton" data-testid='upload-kebab-line-buttons'>
                    {/* <div className="lineBtnContainer"> */}
                    <div className="formLineActionsBtn">
                        {filteredList?.length ?
                            <ViewButton key={`ivb-${index}`} viewSectionId={button.viewSectionId} fns={fns} group={group} index={index} buttonType='uploadList' isFormLocked={isFormLocked}/> :
                            <InlineUploadButton key={`iub-${index}`} group={group} uploadSectionName={lineUploadSectionName} className='lineUploadButton1' index={index} onClick={setLineUploaded} isFormLocked={isFormLocked} entityIndex={entityIndex}/>}
                    </div>
                    <div className="formLineMoreBtn">
                        {/* ellipses are set to hidden on css for now */}
                        <DropdownMenu key={`dpmenu-${index}`} id={idGen} items={items} buttonType="icon" className="moreIcon2" disabled={isFormLocked} data-testid='upload-kebab'>
                            <MoreVertSVGIcon />
                        </DropdownMenu> 
                    </div>
                    {/* </div> */}
                </div>
            );
        case 'uploadOnly':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton" data-testid='upload-line-button'>
                    <div className="formLineActionsBtn">
                        {filteredList?.length ?
                            <ViewButton key={`ivb-${index}`} viewSectionId={button.viewSectionId} fns={fns} group={group} index={index} buttonType='uploadList' isFormLocked={isFormLocked}/> :
                            <InlineUploadButton key={`iub-${index}`} group={group} uploadSectionName={lineUploadSectionName} className='lineUploadButton1' index={index} onClick={setLineUploaded} isFormLocked={isFormLocked} entityIndex={entityIndex}/>}
                    </div>
                </div>
            );
        case 'na':
            return (
                <div key={`ivbgc-${index}`} className="formLineActionsBtn" data-testid='na-kebab-line-buttons'>
                    <InlineNotApplicableButton key={`inab-${index}`} onClick={setLineDisabled} group={group} index={index} className='lineUploadButton1' isFormLocked={isFormLocked}/>
                    {/* ellipses are set to hidden on css for now */}
                    <DropdownMenu key={`dpmenu-${index}`} id={idGen} items={[]} buttonType="icon" className="moreIcon2" disabled={isFormLocked} data-testid='na-kebab'>
                        <MoreVertSVGIcon />
                    </DropdownMenu>
                </div>
            );
        case 'uploadNa':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton" data-testid='upload-na-kebab-line-buttons'>
                    <div className="formLineActionsBtn">
                        {filteredList?.length ?
                            <ViewButton key={`ivb-${index}`} viewSectionId={button.viewSectionId} fns={fns} group={group} index={index} buttonType='uploadList' isFormLocked={isFormLocked}/> :
                            <InlineUploadButton key={`iub-${index}`} group={group} uploadSectionName={lineUploadSectionName} className='lineUploadButton1' index={index} onClick={setLineUploaded} isFormLocked={isFormLocked} entityIndex={entityIndex}/>}
                        <InlineNotApplicableButton key={`inab-${index}`} className="lineNaButton" onClick={setLineDisabled} group={group} index={index} isFormLocked={isFormLocked}/>
                    </div>
                    <div className="formLineMoreBtn">
                        {/* ellipses are set to hidden on css for now */}
                        <DropdownMenu id={idGen} key={`dpmenu-${index}`} items={items} buttonType="icon" className="moreIcon2" disabled={isFormLocked} data-testid='upload-na-kebab'>
                            <MoreVertSVGIcon />
                        </DropdownMenu>
                    </div>
                </div>
            ); 
        case 'more':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton3" data-testid='kebab-line-button'>
                    {/* ellipses are set to hidden on css for now */}
                    <DropdownMenu id={idGen} key={`dpmenu-${index}`} items={items} buttonType="icon" className="moreIcon1" disabled={isFormLocked} data-testid='more-kebab'>
                        <MoreVertSVGIcon />
                    </DropdownMenu>
                </div>
            ); 
        case 'moreLineItemGroup':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton3" data-testid='kebab-line-item-button'>
                    {/* ellipses are set to hidden on css for now */}
                    <DropdownMenu id={idGen} key={`dpmenu-${index}`} items={items} buttonType="icon" className="moreIcon1" disabled={isFormLocked} data-testid='more-line-kebab'>
                        <MoreVertSVGIcon />
                    </DropdownMenu>
                </div>
            ); 
        case 'remove':
            return (
                <div key={`ivbgc-${index}`} className="formLineActionsBtn" data-testid='remove-line-button'>
                    <InlineRemoveButton key={`irb-${index}`} onClick={removeLineItem} group={group} index={index} isFormLocked={isFormLocked}/>
                </div>
            );
        case 'clear':
            return (
                <div key={`ivbgc-${index}`} className="formLineActionsBtn" data-testid='remove-clear-button'>
                    <InlineClearButton key={`irb-${index}`} onClick={clearLineItem} group={group} index={index} isFormLocked={isFormLocked}/>
                </div>
            );
        default:
            return null;
        }
    });

    return buttons;
}

export default LineItemButtons;
