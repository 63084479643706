// common modules
import { useState, useEffect } from "react";
import _ from 'lodash';
import { Tooltipped } from 'react-md';

// custom modules
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import * as STATUS from '@utilities/constants/cardStatus.js';
import api from '@utilities/claApi';
import { calculateDashboardSummary } from "../../../utilities/helpers/calculateDashboardSummary";

const ToDoCounter = (props) => {
  const { organizer } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [toDosCounter, setToDosCounter] = useState('0 of 2');
  const [dashboard, setDashboard] = useState(defaultDashboard);

  useEffect(() => {
    if (!organizer) {
      return;
    }

    api.get(`organizers/${organizer.id}/dashboard`).then((response) => {
      return response.data;
    }).then((dashboardData) => {
      return calculateDashboardSummary(dashboardData?.id, dashboardData.dashboard, organizer);
    }).then((dashboardResults) => {
      setToDosCounter(dashboardResults.summary);
      setDashboard(dashboardResults.dashboard)
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      console.error('unable to create dashboard');
    });

    // eslint-disable-next-line
  }, [organizer]);

  const getStatus = (statusId) => statusId === STATUS.COMPLETED ? 'Completed' : 'Not Completed';

  const buildTodoLine = (requiredForm, index) => {
    return (
      <div key={index}> {`${index + 1}. ${requiredForm?.title} : ${getStatus(requiredForm?.statusId)}`} </div>
    );
  };

  const buildTodosOnHover = (dashboard) => {
    const requiredForms = dashboard?.filter((category) => category?.title !== 'Opportunity Services')
      .map((cat) => cat?.cards?.filter((card) => card?.isRequired))?.flat(2) || [];

    return (<div>{requiredForms.map(buildTodoLine)}</div>);
  };

  const wrapWithToolTip = (child) => {
    return (
      <Tooltipped id={`hover-todos-${organizer.id}`} tooltip={buildTodosOnHover(dashboard)} key={`hover-todos-${organizer.id}`} className='pracDashOnHover'>
        {child}
      </Tooltipped>
    );
  }

  if (isLoading) {
    return wrapWithToolTip(<div>loading...</div>);
  }

  if (!toDosCounter) {
    return wrapWithToolTip(<div></div>);
  }

  return wrapWithToolTip(
    <div>{toDosCounter}</div>
  );
};

export default ToDoCounter;