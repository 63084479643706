import { GridCell } from 'react-md';
import { frameworkSetter } from '../../../../hooks/usePageFramework';

function DetailsLabel(props) {
    const { item, index } = props;
    const { history, NAV } = frameworkSetter.usePageFramework();
    const isVisible = item.isVisible === undefined || item.isVisible;

    return (
        isVisible ? 
            <GridCell 
                key={`lbl-${index}`} 
                colSpan={item.col || 12} className={item.class || 'groupDetails'}
            >
                { item.isMarkup ? <span dangerouslySetInnerHTML={{ __html: item.label}}></span> 
                 : item.label }
                 {(item.linkLabel && item.path) ? <a style={{float: 'right'}} href='javascript: void(0)' onClick={()=>history.push(item.path)}>{item.linkLabel}</a> : ''}
            </GridCell> : 
            null
    );
}

export default DetailsLabel;