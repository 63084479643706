import { useEffect, useState } from 'react';

import DashboardSubMenu from '@components/header/dashboardSubMenu';
import DashboardGrid from '@components/dashboard/dashboardGrid';
import { setToolbarTitle } from '@utilities/actions/global';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings';
import OrganizerUploads from '@components/OrganizerUploads';
import EntryExperienceModal from '@components/entryExperience/entryModal';
import api from '@utilities/claApi';
import {
	Grid,
	GridCell,
	Divider
} from 'react-md';

function Dashboard() {
	const { dispatch, updateCard, selectState, REDUX, history, NAV } = usePageFramework();
	const dashboard = selectState(REDUX.DASHBOARD);
	const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);
	const categories = dashboard ? dashboard.filter((category) => category.title !== 'Opportunity Services') : [];

	// use to checked if user already login
	const hasSkipEntryStep = selectState(REDUX.HAS_SKIP_ENTRY_STEP);
	const taxYear = selectState(REDUX.YEAR);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const organizerId = selectState(REDUX.ORGANIZER_ID);
	const spouseEmail = selectState(REDUX.SPOUSE_EMAIL);

	const [clientEmail, setClientEmail] = useState('');

	useEffect(() => {
		if (!organizerId) {
			setClientEmail('');
			return;
		}

		api.get(`/organizers/${organizerId}/users`)?.then((response) => {
			const nonClaEmails = response.data.results.filter((result) => result.email.split("@")[1] !== 'claconnect.com')
			if (nonClaEmails.length > 0) {
				setClientEmail(nonClaEmails[0].email)
			}

			return response.data.results
		}).catch((err) => {
			setClientEmail('');
		});

	}, [organizerId]);


	useEffect(() => {
		dispatch(setToolbarTitle(STRING.DASHBOARD));
		updateCard();
	});

	useEffect(() => {
		// pop entry Experience modal if Client user first time login
		(isFutureYear || currentYear === taxYear) && !isPractitioner && !isAdmin && !hasSkipEntryStep && history.push(NAV.ENTRY_INTERVIEW)
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Grid style={{ marginLeft: '30px', marginRight: '30px' }}>
				<p>
                    { clientEmail ? <><span style={{ fontWeight: "bold", marginLeft: '60px' }}>Taxpayer:</span>{clientEmail}</> : null }
                    { spouseEmail ? <><span style={{ fontWeight: "bold", marginLeft: "20px" }}>Spouse:</span>{spouseEmail}</> : null }
                </p>
				<GridCell colSpan={12} className="categoryCell">
					<GridCell style={{ width: '94%', position: 'relative', margin: '0 auto', marginBottom: '-30px' }} colSpan={8} id="dashboardCategoryDivider">
						<Divider />
					</GridCell>
				</GridCell>
			</Grid>
			<OrganizerUploads style={{ top: '30px' }} />
			{isFutureYear ?
				<div className='formBuilderContainer futureContentContainer'>
					<div className='futureHeadText'>Your future tax year has not yet been created.</div>
					<div className='uploadText'>Upload files for your future tax year above.</div>
					<div className='contentText'>You can upload applicable documents in the Document Manager tab, or upload in bulk above.</div>
					<div className='contentText'>For the current tax year, please change the tax year in the dropdown selection above to upload files.</div>
				</div> :
				<>

					<div className="dashboardSize">
						<DashboardSubMenu />
						<DashboardGrid categories={categories} />
					</div>
				</>
			}
			<EntryExperienceModal />
		</>
	);
}

export default Dashboard;
